import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['content', 'previewBtn'];

  updatePreview() {
    $('.email-body').html(this.contentTarget.editor.element.innerHTML);
  }

  changeTemplate(event) {
    $.ajax({
      method: 'GET',
      url: event.target.dataset.url,
      data: {email_template_id: event.target.value}
    }).done(() => {
      $('.email-body').html(this.contentTarget.editor.element.innerHTML);
    });

  }
}

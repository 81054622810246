import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ['editor']

  initialize() {
    this.editorTargets.forEach(el => {
      $(el).find('.trix-button--icon-link').remove();
      $(el).find('.trix-button--icon-heading-1').remove();
      $(el).find('.trix-button-group--file-tools').remove();
      $(el).find('.trix-button-group--history-tools').remove();
    });
  }


}

import { Controller } from "stimulus";

export default class extends Controller {

  moveUp(event) {
    event.preventDefault();
    try { $('[data-rel="tooltip"]').tooltip('hide'); } catch(e) {}
    let currentImage = $("#"+event.currentTarget.closest('.composition-image').id);
    currentImage.prev().insertAfter(currentImage);
    this.postUpdatedOrder(currentImage.closest('.composition-images'));
  }

  moveDown(event) {
    event.preventDefault();
    try { $('[data-rel="tooltip"]').tooltip('hide'); } catch(e) {}
    let currentImage = $("#"+event.currentTarget.closest('.composition-image').id);
    currentImage.next().insertBefore(currentImage);
    this.postUpdatedOrder(currentImage.closest('.composition-images'));
  }

  postUpdatedOrder(compositionImages) {
    let imageIds = [];
    let images = compositionImages.find('.composition-image');
    images.each((idx) => {
      imageIds.push(images[idx].dataset.id);
    });
    let reorderUrl = compositionImages.data('reorder-url');
    $.post(reorderUrl, {imageIds: imageIds});
  }

}

$(function() {

  $(document).on('click', '#sidebarCollapse', function () {
    $('#sidebar, .main').toggleClass('collapsed');
    $('.collapse.in').toggleClass('in');
    $('a[aria-expanded=true]').attr('aria-expanded', 'false');
  });

  $(document).on('click', '#sidebar .with-nested', function () {
    $(this).find('.nested').show();
  });

  $(document).on('click', '.sidebar-collapse', function() {
    $.ajax({
      method: 'POST',
      url: '/toggle_sidebar_mode',
      data: { collapsed: $('#sidebar').hasClass('collapsed') }
    })
  });

  //RIGHT SIDEBAR
  $(document).on('click', '#dismiss, .overlay', function () {
    // hide sidebar
    $('#rightSidebar').removeClass('active');
    // hide overlay
    $('.sidebar-overlay').removeClass('active');
  });
  $(document).on('click', '#rightSidebarCollapse', function () {
    // open sidebar
    $('#rightSidebar').addClass('active');
    // fade in the overlay
    $('.sidebar-overlay').addClass('active');
    //$('.collapse.in').toggleClass('in');
    //$('a[aria-expanded=true]').attr('aria-expanded', 'false');
  });

});

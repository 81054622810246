import {Controller} from 'stimulus';

export default class extends Controller {

  static targets = ['inputWrapper', 'previewWrapper'];

  connect() {
    this.inputWrapperTarget.style.display = 'none';
    this.previewWrapperTarget.style.display = 'block';
  }

  change(e) {
    e.preventDefault();
    e.stopPropagation();

    this.inputWrapperTarget.style.display = 'block';
    this.previewWrapperTarget.style.display = 'none';
    e.currentTarget.style.display = 'none';
  }

}

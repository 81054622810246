// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.Trix = require("trix")

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

require("trix")
require("@rails/actiontext")

require.context('../images', true);
require("../stylesheets/application.scss");
require("../app");

import "controllers"

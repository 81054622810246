import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ['submitBtn']

  connect() {
    console.log('search-form controller');
  }

  toggleLabeledCheckbox(e) {
    let el = $(e.currentTarget);
    let checkboxesGroup = el.closest('.checkbox-group');

    // Rimuovo gli eventuali elementi selezionati
    checkboxesGroup.find('label').not(el).each(function()  {
      console.log($(this).html());
      $(this).removeClass('pressed');
      let check_box = $(this).next();
      check_box.prop('checked', false);
    });

    if (el.hasClass('pressed')) {
      el.removeClass('pressed');
    } else {
      el.addClass('pressed');
    }
    let check_box = el.next();
    check_box.prop('checked', el.hasClass('pressed'));

    $(this.submitBtnTarget).trigger('click');
  }

}

import {Controller} from 'stimulus';
import {preventOverflow} from "@popperjs/core";

export default class extends Controller {
  static targets = ['attachmentWrapper', 'videoUrlWrapper', 'documentKind'];

  connect() {
    this.manageKind();
  }

  manageKind() {
    let selectedKind = $(this.documentKindTarget).val();
    if(selectedKind == 'attachment') {
      $(this.videoUrlWrapperTarget).hide();
      $(this.videoUrlWrappertarget).find('input').val('');
      $(this.attachmentWrapperTarget).show();
    } else {
      $(this.attachmentWrapperTarget).hide();
      $(this.attachmentWrapperTarget).find('input').val('');
      $(this.videoUrlWrapperTarget).show();
    }
  }

}

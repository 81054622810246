import {Controller} from 'stimulus';
export default class extends Controller {
  static targets = ['emailInput', 'deletedSelect'];

  connect() {
    console.log("Contact form controller");
  }

  updateEmail() {
    let isDeleted = this.deletedSelectTarget.value;
    let email = this.emailInputTarget.value;

    console.log("isDeleted", isDeleted);
    console.log("email", email);

    if(isDeleted == 'false' && email.startsWith('DELETED.')) {
      console.log('sono quiii');
      this.emailInputTarget.value = email.replace('DELETED.', '')
    }
  }

}

import {Controller} from 'stimulus';

export default class extends Controller {

  static targets = ['email', 'infobox'];

  connect() {
    $('input[type="submit"]').prop("disabled", true);
  }

  initialize() {
    const emailTarget = $(this.emailTarget);

    $(this.emailTarget).typeahead({
      order: null,
      maxItems: 10,
      dynamic: true,
      delay: 100,
      cancelButton: false,
      template: function (query, item) {
        return "<span><b>{{email}}</b><br />{{name}} {{surname}}</span>"
      },
      emptyTemplate: function (query) {
        return $('<li>', {
          "text": "Nessuna email trovata per \"" + query + "\"",
          "class": "my-custom-class"
        });
      },
      source: {
        display: ['email', 'name', 'surname'],
        url: [
          {
            url: '/contacts/autocomplete_contact',
            data: {
              query: "{{query}}"
            }
          }, 'contacts'
        ]
      },
      callback: {
        onResult: function (node, query, result, resultCount, resultCountPerGroup) {
          console.log(node, query, result, resultCount, resultCountPerGroup);
          if(query.length > 3) {
            if (resultCount === 0) {
              $('input[type="submit"]').prop("disabled", false);

              $('.infobox').html(`
              <div class="alert alert-warning">
                NON è stato trovato nessun contatto con email<br /><b>${query}</b>.<br /><br />
                <b>Prosegui</b> per inserire le <b>informazioni richieste</b>.
              </div>
            `);
            } else {
              $('.infobox').html("");
            }
          } else {
            $('input[type="submit"]').prop("disabled", true);
            $('.infobox').html("");
          }
        },
        onClickAfter: function (node, a, item, event) {
          if (item.already_granted) {
            $('input[type="submit"]').prop("disabled", true);

            $('.infobox').html(`
            <div class="alert alert-info">
              È stato trovato un contatto con email<br /><b>${item.email}</b>.<br /><br />
              L'utente è <b>già abilitato</b> sull'<b>area riservata</b>.
            </div>
          `);
          } else {
            $('input[type="submit"]').prop("disabled", false);

            $('.infobox').html(`
            <div class="alert alert-info">
              È stato trovato un contatto con email<br /><b>${item.email}</b>.<br /><br />
              Proseguendo <b>abiliterai</b> l'utente per l'accesso all'<b>area riservata</b>.
            </div>
          `);
          }
        }
      }
    })
  }
}

import "bootstrap";
import iziToast from "izitoast";
import 'select2'
import initializeApp from './initializeApp';

import "./jquery.typeahead";

import './trix_extensions';

import './sidebar';
import './search';

iziToast.settings({
  timeout: 8000,
  position: "topRight"
});

document.addEventListener("turbolinks:load", function() {
  initializeApp();
});

document.addEventListener("ajax:complete", function() {
  initializeApp();
});

$('.check_filters').on('click', 'input', function(event) {
  $(this).closest('form').submit();
});

$(document).on('change', '[data-autosubmit="change"]', function(event) {
  if($(this).closest('form').find('input[type="submit"]').length) {
    $(this).closest('form').find('input[type="submit"]').trigger('click');
  } else {
    $(this).closest('form').submit();
  }
});
